<template>
  <div>
    <app-page-detail page-title="Edit Tender" page-toolbar-title="Edit Tender">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @btn-save-action="updateData()"></action-button>
      </template>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" lg="9">
              <v-form :loading="loading" :disabled="loading">
                <v-row>
                  <v-col>
                    <card-expansion title="General" :loading="loading">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field dense disabled v-model="tender.document_no_" placeholder="Document No." label="Document No." outlined hide-details class="mb-2"></v-text-field>
                          <v-text-field dense disabled v-model="tender.document_date" type="date" placeholder="Document Date" label="Document Date" outlined hide-details class="mb-2"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field dense disabled v-model="tender.document_type" placeholder="Document Type" label="Document Type" outlined hide-details class="mb-2"></v-text-field>
                          <v-text-field dense v-model="tender.due_date" placeholder="Due Date" label="Due Date" type="Date" outlined hide-details class="mb-2"></v-text-field>
                          <!-- <v-select dense v-model="selected_published" placeholder="Published" label="Published" hide-details outlined class="mb-2" :items="published_list" item-text="label" item-value="value" return-object></v-select> -->
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-textarea dense v-model="tender.description" placeholder="Description" label="Description" outlined hide-details class="mb-2"></v-textarea>
                        </v-col>
                      </v-row>
                    </card-expansion>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <card-expansion title="Items" class="mb-3" :loading="card_item.loading" no-padding>
                      <template #action>
                        <v-btn icon text small @click="card_item.dialog = true">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn icon text small @click="getDetails(true)">
                          <v-icon>mdi-sync</v-icon>
                        </v-btn>
                      </template>
                      <v-data-table :options.sync="card_item.datatable_options" :loading="card_item.datatable.loading" :loading-text="card_item.datatable.loadingText" :headers="card_item.datatable.columns" :items="card_item.datatable.data" :search="card_item.search" :server-items-length="card_item.datatable.totalRows" :items-per-page="card_item.datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="card_item.pageCount = $event">
                        <template v-slot:[`item.id`]="{ item }">
                          <v-btn icon text small :value="item.id" @click="$refs.refDialogPr.showDialog(item.id)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                          <v-btn icon text small :value="item.id" @click="editDetail(item)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn icon text small color="error" :value="item.id" @click="deleteDetails(item.id)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ card_item.search }}" found no results.</v-alert>
                      </v-data-table>
                      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
                        <v-pagination v-model="card_item.datatable_options.page" :length="card_item.totalPage"></v-pagination>
                      </div>
                    </card-expansion>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <card-expansion title="Attachments">
                      <v-text-field v-model="tender.attachment1_title" label="Attachment 1" outlined class="mb-2" hide-details dense append-icon="mdi-upload" @click:append="uploadFile(1)"></v-text-field>
                      <p v-if="tender.attachment1_src"><a :href="tender.attachment1_src" target="_blank">{{ tender.attachment1_src }}</a> <v-icon @click="tender.attachment1_src = null" small color="error">mdi-delete</v-icon> </p>
                      <v-text-field v-model="tender.attachment2_title" label="Attachment 2" outlined class="mb-2" hide-details dense append-icon="mdi-upload" @click:append="uploadFile(2)"></v-text-field>
                      <p v-if="tender.attachment2_src"><a :href="tender.attachment2_src" target="_blank">{{ tender.attachment2_src }}</a> <v-icon @click="tender.attachment2_src = null" small color="error">mdi-delete</v-icon> </p>
                      <v-text-field v-model="tender.attachment3_title" label="Attachment 3" outlined class="mb-2" hide-details dense append-icon="mdi-upload" @click:append="uploadFile(3)"></v-text-field>
                      <p v-if="tender.attachment3_src"><a :href="tender.attachment3_src" target="_blank">{{ tender.attachment3_src }}</a> <v-icon @click="tender.attachment3_src = null" small color="error">mdi-delete</v-icon> </p>
                      <v-text-field v-model="tender.attachment4_title" label="Attachment 4" outlined class="mb-2" hide-details dense append-icon="mdi-upload" @click:append="uploadFile(4)"></v-text-field>
                      <p v-if="tender.attachment4_src"><a :href="tender.attachment4_src" target="_blank">{{ tender.attachment4_src }}</a> <v-icon @click="tender.attachment4_src = null" small color="error">mdi-delete</v-icon> </p>
                    </card-expansion>
                  </v-col>
                </v-row>

              </v-form>
            </v-col>

            <v-col cols="12" lg="3">
              <document-info :value="tender"></document-info>
              <app-comment :document_id="parseInt(tender.id)" :source_document="tender.source_document" :document_no_="tender.document_no_"></app-comment>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </app-page-detail>

    <app-dialog v-model="card_item.dialog" :title="dialog_pr.title">
      <template #toolbar>
        <v-btn icon @click="refreshDialogPr()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon @click="dialog_pr.showFilter = !dialog_pr.showFilter">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="applyPr()" :loading="dialog_pr.loading_apply">
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <div style="max-width: 320px">
          <v-text-field v-model="dialog_pr.search" placeholder="Search" outlined dense hide-details rounded @change="filterDataPr()" append-icon="mdi-magnify" @click:append="getDataPr()"></v-text-field>
        </div>
      </template>
      <template #actions>
        <v-pagination v-model="dialog_pr.datatable_options.page" :length="dialog_pr.totalPage" :total-visible="7"></v-pagination>
      </template>
      <template #actions-right>
        <v-btn color="primary" @click="applyPr()" :loading="dialog_pr.loading_apply">Apply</v-btn>
      </template>
      <v-row>
        <v-col :lg="(dialog_pr.showFilter) ? '3' : '12'" v-if="dialog_pr.showFilter">
          <v-sheet class="px-4 py-2" elevation="1" rounded>
            <h3 class="mb-3">Filter</h3>
            <v-text-field v-model="dialog_pr.search" placeholder="Search" outlined dense hide-details class="mb-2" @keyup.enter="filterDataPr()"></v-text-field>
            <v-text-field v-model="dialog_pr.filter.campaign_no_" placeholder="Campaign" outlined dense hide-details class="mb-2" @keyup.enter="filterDataPr()"></v-text-field>
            <v-autocomplete v-model="dialog_pr.filter.requester_division" :items="dialog_pr.requesterDivisionList" item-text="label" item-value="value" outlined hide-details dense label="Requester Division" class="mb-2"></v-autocomplete>

            <v-btn small color="primary" @click="filterDataPr()" :loading="dialog_pr.datatable.loading" class="mr-1">Filter</v-btn>
            <v-btn small @click="dialog_pr.showFilter = false">Hide</v-btn>
          </v-sheet>
        </v-col>
        <v-col :lg="(dialog_pr.showFilter) ? '9' : '12'">
          <v-card class="py-1">
            <v-data-table :options.sync="dialog_pr.datatable_options" :loading="dialog_pr.datatable.loading" :loading-text="dialog_pr.datatable.loadingText" :headers="dialog_pr.datatable.columns" :items="dialog_pr.datatable.data" :search="dialog_pr.search" :server-items-length="dialog_pr.datatable.totalRows" :items-per-page="dialog_pr.datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="dialog_pr.pageCount = $event">
              <template v-slot:[`item.checked`]="{ item }">
                <v-checkbox v-model="item.checked" hide-details dense class="my-0 py-0"></v-checkbox>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog v-model="dialog_edit_detail.dialog" :title="dialog_edit_detail.title" max-width="500px">
      <template #toolbar>
        <v-btn icon @click="updateDetail()">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </template>
      <template #actions>
        <v-btn color="primary" @click="updateDetail()">Update</v-btn>
      </template>
      <v-row>
        <v-col>
          <card-expansion title="Edit">
            <v-text-field disabled v-model="dialog_edit_detail.data.item_no_" outlined hide-details dense class="mb-2" placeholder="Item No." label="Item No."></v-text-field>
            <v-textarea v-model="dialog_edit_detail.data.description" outlined hide-details dense class="mb-2" placeholder="Description" label="Description"></v-textarea>
            <v-textarea v-model="dialog_edit_detail.data.specification" outlined hide-details dense class="mb-2" placeholder="Specification" label="Specification"></v-textarea>
            <v-text-field v-model="dialog_edit_detail.data.uom" outlined hide-details dense class="mb-2" placeholder="Unit" label="Unit"></v-text-field>
            <v-text-field v-model="dialog_edit_detail.data.quantity" outlined hide-details dense class="mb-2" placeholder="Quantity" label="Quantity" type="number"></v-text-field>
          </card-expansion>
        </v-col>
      </v-row>
    </app-dialog>

    <DialogPr ref="refDialogPr"></DialogPr>

    <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />
  </div>
</template>

<script>
import ActionButton from './components/ActionButton.vue';
import DocumentInfo from './components/DocumentInfo.vue';
import DialogPr from './components/DialogPr.vue';
export default {
  components: {
    ActionButton,
    DocumentInfo,
    DialogPr,
  },
  data() {
    return {
      id: null,
      loading: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_approve: false,
      loading_cancel: false,
      dialog: false,
      modal1: false,
      tab: null,
      data_detail: {},
      tender: {},
      items: {},
      notFound: false,

      published_list: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      selected_published: {},

      card_item: {
        dialog: false,
        loading: null,
        search: "",
        datatable_options: {
          itemsPerPage: 20
        },
        itemsPerPageOption: [20, 50, 100, 500],
        totalPage: 0,
        datatable: {
          loading: null,
          loadingText: "Loading... Please wait...",
          totalRows: 0,
          columns: [
            { text: "#", value: "id", class: "text-no-wrap" },
            { text: "Item No.", value: "item_no_", class: "text-no-wrap" },
            { text: "Description", value: "description", class: "text-no-wrap" },
            // { text: "Specification", value: "document_type", class: "text-no-wrap" },
            { text: "UoM", value: "uom", class: "text-no-wrap" },
            { text: "Quantity", value: "quantity", class: "text-no-wrap", cellClass: "text-no-wrap py-0 text-end" },
          ],
          data: [],
        },
        filter: {}
      },

      dialog_pr: {
        title: "Add rrom PR NAV",
        loading: null,
        search: "",
        datatable_options: {
          itemsPerPage: 10
        },
        itemsPerPageOption: [10, 20, 50, 100, 500],
        totalPage: 0,
        datatable: {
          loading: null,
          loadingText: "Loading... Please wait...",
          totalRows: 0,
          columns: [
            { text: "#", value: "checked", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "PR No.", value: "no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Line No.", value: "line_no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Item No.", value: "item_no_", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Description", value: "description", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Description 2", value: "description2", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Specification", value: "specification", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Due Date", value: "due_date", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "UoM", value: "uom", class: "text-no-wrap", cellClass: "text-no-wrap py-0" },
            { text: "Quantity", value: "quantity", class: "text-no-wrap", cellClass: "text-no-wrap py-0 text-end" },
          ],
          data: [],
        },
        showFilter: false,
        filter: {
          requester_division: null,
        },
        requesterDivisionList: [],
        loading_apply: false,

      },
      loading_delete_detail: false,
      dialog_edit_detail: {
        dialog: false,
        data: {},
        title: "Edit Detail",
        loading: false
      },

      attachmentToUpload: null,
    };
  },
  watch: {
    card_item: {
      handler() {
        if (this.card_item.dialog == true) {
          this.refreshDialogPr()
        }
      },
      deep: true,
    },
    'dialog_pr.datatable_options': {
      handler() {
        this.getDetails(true);
      },
      deep: true,
    },
    selected_published(n) {
      this.tender.published = n.value
    }
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "tender/detail");

      this.showLoadingOverlay(true);


      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("tender/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.tender = res.data.data.tender;
          this.selected_published = {
            label: (res.data.data.tender.published) ? 'Yes' : 'No',
            value: res.data.data.tender.published
          }
          this.items = res.data.data.items;
          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {

          if (error.response.status == 404) {
            this.redirect404()
          }

          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async getDetails(refresh) {
      const uri = "tender/data-detail";

      this.card_item.filter.tender_header_id = this.dtId;

      if (refresh) this.AxiosStorageRemove("POST", uri);
      this.card_item.datatable.loading = true;
      var formData = this.dataTableFormData(this.card_item.datatable_options, this.card_item.search, this.card_item.filter);

      await this.$axios
        .post(uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.card_item.datatable.data = resData.data.results;
            this.card_item.datatable.totalRows = resData.data.paging.total_rows;
            this.card_item.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.card_item.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.card_item.datatable.loading = false;
        });
    },

    async getDataPr(refresh) {
      const uri = "tender/data-pr";
      if (refresh) this.AxiosStorageRemove("POST", uri);
      this.dialog_pr.datatable.loading = true;
      var formData = this.dataTableFormData(this.dialog_pr.datatable_options, this.dialog_pr.search, this.dialog_pr.filter);

      await this.$axios
        .post(uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.dialog_pr.datatable.data = resData.data.results;
            this.dialog_pr.datatable.totalRows = resData.data.paging.total_rows;
            this.dialog_pr.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.dialog_pr.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.dialog_pr.datatable.loading = false;
        });
    },

    async getRequesterDivision(refresh) {
      this.dialog_pr.requesterDivisionList = [];

      const uri = "tender/requester-division";
      if (refresh) this.AxiosStorageRemove("GET", uri);
      await this.$axios
        .get(uri)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            if (resData.data.length >= 1) {
              this.dialog_pr.requesterDivisionList.push("");
              resData.data.forEach((item) => {
                this.dialog_pr.requesterDivisionList.push(item);
              })
            }
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
        });
    },

    async applyPr() {
      const uri = 'tender/apply-pr';
      let selectedRows = [];

      this.dialog_pr.datatable.data.forEach((r) => {
        if (r.checked === true) {
          selectedRows.push(r)
        }
      })

      if (selectedRows.length < 1) {
        this.showAlert("error", "Select PR");
        this.dialog_pr.loading_apply = false;
        return;
      }

      const data = {
        tender_header_id: this.dtId,
        purchase_request: selectedRows,
      };

      this.dialog_pr.loading_apply = true;
      await this.$axios.post(uri, this.objectToFormData(data))
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.dialog_pr.loading_apply = false;
          this.getDetails(true);

          this.card_item.dialog = false;
        })
        .catch((error) => {
          this.dialog_pr.loading_apply = false;
          this.axiosErrorHandler(error);
          this.card_item.dialog = false;
          this.getDetails(true);
        });
    },

    filterDataPr() {
      this.dialog_pr.datatable_options.page = 1;
      this.getDataPr();
    },

    refreshDialogPr() {
      this.getDataPr();
      this.getRequesterDivision();
    },

    editDetail(item) {
      var newItem = item
      this.dialog_edit_detail.data = newItem;
      newItem = undefined
      this.dialog_edit_detail.dialog = true;
    },

    async deleteDetails(id) {
      let post = async () => {
        this.loading_delete_detail = true;
        this.showLoadingOverlay(true);
        await this.$axios
          .delete("tender/delete-detail/" + id)
          .then((res) => {
            this.loading_delete_detail = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);


            this.getDetail(true);
            this.getDetails(true);
          })
          .catch((error) => {
            this.loading_delete_detail = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete detail?", post);
    },

    async updateDetail() {
      this.dialog_edit_detail.loading = true;
      this.showLoadingOverlay(true);
      var data = this.dialog_edit_detail.data;
      await this.$axios
        .post("tender/update-detail", this.objectToFormData(data))
        .then((res) => {
          this.dialog_edit_detail.loading = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);
          this.dialog_edit_detail.dialog = false;
          this.refreshDetail();
        })
        .catch((error) => {
          this.dialog_edit_detail.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async updateData() {
      this.loading = true;
      this.showLoadingOverlay(true);
      var data = {
        ...this.tender,
        detail: this.card_item.datatable.data
      };
      await this.$axios
        .post("tender/update", this.objectToFormData(data))
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);
          this.refreshDetail();
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    uploadFile(attachment) {
      this.$refs.reffileupload.click();
      this.attachmentToUpload = attachment;
    },

    handleFileUpload() {
      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("tender/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.tender['attachment' + this.attachmentToUpload + '_src'] = resData.data.file_src;
          if (this.tender['attachment' + this.attachmentToUpload + '_title'].length == 0)
          {
            this.tender['attachment' + this.attachmentToUpload + '_title'] = this.getFileNameFromUrl(resData.data.file_src);
          }
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("tender", "modify", true);

    this.getDetail(true);
    this.getDetails(true);
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
